import React from "react"
import { ThemeProvider } from "./src/context/ThemeContext"
import { StravaProvider } from "./src/context/StravaContext"

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
      <StravaProvider>
        {element}
      </StravaProvider>
  </ThemeProvider>
)
