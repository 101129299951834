import React, { useState, createContext } from 'react'

const StravaContext = createContext()

export const StravaProvider = ({ children }) => {
  const stravaClientID = process.env.REACT_APP_STRAVA_CLIENT_ID
  const stravaClientSecret = process.env.REACT_APP_STRAVA_CLIENT_SECRET
  const stravaRefreshToken = process.env.REACT_APP_STRAVA_REFRESH_TOKEN
  const auth_link = `https://www.strava.com/oauth/token`
  const [stravaActivities, setStravaActivities] = useState([])
  const [stravaTotals, setStravaTotals] = useState([])

  const getAllStats = async (res) => {
    const athlete_link = `https://www.strava.com/api/v3/athlete?access_token=${res.access_token}`
    let athleteID = await fetch(athlete_link).then((res) =>
      res.json().then((data) => data.id)
    )

    const stat_link = `https://www.strava.com/api/v3/athletes/${athleteID}/stats?access_token=${res.access_token}`

    let stats = await fetch(stat_link).then((res) => res.json())

    return stats
  }

  const getActivities = async (res, pageNo) => {
    const activities_link = `https://www.strava.com/api/v3/athlete/activities?access_token=${res.access_token}&per_page=50&page=${pageNo}`

    let apiResults = await fetch(activities_link).then((response) =>
      response.json()
    )
    return apiResults
  }

  const getAllActivities = async function (res, pageNo = 1) {
    const results = await getActivities(res, pageNo)
    console.log('Retreiving data from API for page : ' + pageNo)
    if (results.length > 0) {
      return results.concat(await getAllActivities(res, pageNo + 1))
    } else {
      return results
    }
  }

  const reAuthorize = async () => {
    let authData = fetch(auth_link, {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },

      body: JSON.stringify({
        client_id: stravaClientID,
        client_secret: stravaClientSecret,
        refresh_token: stravaRefreshToken,
        grant_type: 'refresh_token',
      }),
    }).then((res) => res.json())

    if (localStorage.getItem("stravaData") !== "true" && JSON.parse(localStorage.getItem('stravaData').message !== 'Rate Limit Exceeded')) {
      authData
        .then((res) => getAllActivities(res))
        .then((data) => {
          setStravaActivities(data)
          localStorage.setItem('stravaData', JSON.stringify(data))
        })
    }

    if (localStorage.getItem("stravaTotals") !== "true" && JSON.parse(localStorage.getItem('stravaTotals').message !== 'Rate Limit Exceeded')) {
      authData
        .then((res) => getAllStats(res))
        .then((data) => {
          setStravaTotals(data)
          localStorage.setItem('stravaTotals', JSON.stringify(data))
        })
        .then((data) => console.log('data', data))
    }

  }

  return (
    <StravaContext.Provider
      value={{
        stravaActivities,
        setStravaActivities,
        reAuthorize,
        getActivities,
        stravaTotals,
        setStravaTotals,
      }}
    >
      {children}
    </StravaContext.Provider>
  )
}

export default StravaContext
