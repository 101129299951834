import React from 'react'

import { createContext, useState } from 'react'

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('theme--light')
  const [stravaActivities, setStravaActivities] = useState([])

  const handleThemeChange = (themeName) => {
    let bodyClass = document.querySelector('body')

    if (themeName === 'theme--light') {
      setTheme('theme--dark')
      bodyClass.className = 'theme--dark'
    } else {
      setTheme('theme--light')
      bodyClass.className = 'theme--light'
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, handleThemeChange, setStravaActivities, setStravaActivities }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext
